export default {
  name: "edit",
  data() {
    return {
      loading: true,
      // 表单加载状态
      submitLoading: false,
      // 表单提交状态
      form: {
        id: "",
        name: ""
      },
      // 表单验证规则
      formValidate: {
        name: [{
          required: true,
          message: "请输入",
          trigger: "blur"
        }]
      },
      backRoute: ""
    };
  },
  methods: {
    init() {
      this.handleReset();
      this.form.id = this.$route.query.id;
      this.backRoute = this.$route.query.backRoute;
      this.getData();
    },
    handleReset() {
      this.$refs.form.resetFields();
    },
    getData() {
      this.loading = true;
      // this.getRequest("请求地址，如/getById/" + this.form.id).then(res => {
      //   this.loading = false;
      //   if (res.success) {
      //     // 转换null为""
      //     let v = res.result
      //     for (let attr in v) {
      //       if (v[attr] == null) {
      //         v[attr] = "";
      //       }
      //     }
      //     let str = JSON.stringify(v);
      //     let data = JSON.parse(str);
      //     this.form = data;
      //   }
      // });
      // 模拟获取数据成功
      this.loading = false;
      if (this.form.id == "1") {
        this.form.name = "XBoot";
      } else {
        this.form.name = "Exrick";
      }
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // this.postRequest("请求路径", this.form).then(res => {
          //   this.submitLoading = false;
          //   if (res.success) {
          //     this.$Message.success("编辑成功");
          //     this.closeCurrentPage();
          //   }
          // });
          // 模拟成功
          this.submitLoading = false;
          this.$Message.success("编辑成功");
          this.closeCurrentPage();
        }
      });
    },
    // 关闭当前页面
    closeCurrentPage() {
      this.$store.commit("removeTag", "edit");
      localStorage.pageOpenedList = JSON.stringify(this.$store.state.app.pageOpenedList);
      this.$router.push({
        name: this.backRoute
      });
    }
  },
  watch: {
    // 监听路由变化通过id获取数据
    $route(to, from) {
      if (to.name == "edit") {
        this.handleReset();
        this.form.id = this.$route.query.id;
        this.getData();
      }
    }
  },
  mounted() {
    this.init();
  }
};